<template>
  <v-container class='pt-8'>
    <v-card :loading='loading' :disabled='loading' class='rounded-lg'>
      <v-card-title class='primary white--text'>
        <span>Files Received</span>
        <v-spacer></v-spacer>
        <v-btn fab small text dark @click='showInstruction'><v-icon>mdi-information-outline</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols=12 class=''>
            <v-data-table
              dense
              :items-per-page="-1" hide-default-footer
              class='dataTable'
              :items='files'
              item-key='xid'
              :headers='headers'
              no-data-text="No Files"
            >
              <template v-slot:item.file="{item}">
                <div class='d-flex align-center clickFile' @click='clickFile(item.file)'>
                  <v-icon :color='ext(item.file).colour' class='mr-2' size='28'>{{ext(item.file).icon}}</v-icon>
                  <div>{{item.file.filename}}</div>
                </div>
              </template>
              <template v-slot:item.created_at="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span class='font-italic text-caption' v-on='on' v-bind='attrs'>{{moment(item.created_at).from()}}</span>
                  </template>
                  <span>{{moment(item.created_at).format('MMM D, Y')}}</span>
                </v-tooltip>
              </template>
              <template #item.actions="{item}">
                <v-btn fab small text class='primary--text' @click='renameFile(item.file)'>
                  <v-icon>mdi-rename-box</v-icon>
                </v-btn>
                <v-btn fab small text class='primary--text' @click='attachFile(item)'>
                  <v-icon>mdi-briefcase-plus</v-icon>
                </v-btn>
                <v-btn fab small text class='primary--text' @click='downloadFile(item.file)'>
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn fab small text class='red--text' @click='deleteFile(item.file)'>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Job Picker -->
    <v-dialog
      :value="jobPicker.item !== null"
      persistent
      max-width="500"
    >
      <v-card v-if='jobPicker.item !== null' :loading='jobPicker.loading?"white":false' :disabled='jobPicker.loading'>
        <v-card-title class='primary white--text'>
          Attach to Job
        </v-card-title>
        <v-card-text class='py-4 mb-0 secondary--text'>
          <div>
            <v-icon :color='ext(jobPicker.item.file).colour' class='mr-2' size='28'>{{ext(jobPicker.item.file).icon}}</v-icon>
            <span class='black--text'>{{jobPicker.item.file.filename}}</span>
          </div>
          <div class='font-italic text-caption my-1 ml-9'>{{jobPicker.item.subject}}</div>
          <JobSelector class='mt-4' :value='jobPicker.job' @change='j => { jobPicker.job = j; }' />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="jobPicker.item = null">Cancel</v-btn>
          <v-btn color="primary darken-1" @click="attachFileSubmit" :disabled='jobPicker.job === null'>Attach</v-btn>
          <v-btn color="primary darken-1" @click="attachFileSubmitAndGo" :disabled='jobPicker.job === null'>Attach &amp; Go</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<style lang="scss" scoped>
.dataTable{
  tbody td:hover{ cursor: pointer; }
}
.clickFile{
  &:hover{
    color: #18E;
    cursor: pointer;
  }
}
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Moment from 'moment';
import FileTypes from '@/components/Common/FileTypes'
import JobSelector from '@/components/Job/Selector';
export default {
  name: 'Filebox',
  components: {
    JobSelector
  },
  data(){ return {
    loading: false,
    files: [],
    headers: [
      { text: "File", value: 'file' },
      { text: "Description", value: 'subject' },
      { text: "From", value: 'from' },
      { text: "Received", value: 'created_at' },
      { text: "", value: 'actions', align: 'right' },
    ],
    extensions: FileTypes,
    jobPicker: {
      loading: false,
      item: null,
      job: null,
    }
  }},
  watch: {
    $route: { handler(){ this.SET_TITLE('File Inbox'); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "CONFIRM", "PROMPT", "ALERT", "NAVIGATE"]),
    ...mapActions("API", ["API"]),
    moment(m){ return new Moment(m); },
    refresh(){
      this.loading = true;
      this.API({ endpoint: 'filebox' })
      .then(files => { this.files = files; })
      .finally(() => { this.loading = false; })
    },
    ext(file){
      let filename = file.filename ?? "";
      let file_ext = this.fileExtension(filename).toLowerCase();
      return this.extensions[file_ext] ?? this.extensions._default;
    },
    fileExtension(filename){
      if(filename.lastIndexOf('.') != -1)
        return filename.split('.').slice(-1)[0];
      return "";
    },
    clickFile(file){
      let action = this.ext(file).action;
      if(action == 'download') this.downloadFile(file);
      if(action == 'new_tab') this.open_new_tab(file);
    },
    downloadFile(file){
      window.location.href = `${window.app.config.API}/filebox/files/${file.xid}/download`;
    },
    open_new_tab(file){
      window.open(`${window.app.config.API}/filebox/files/${file.xid}`, '_blank');
    },
    renameFile(f){
      let baseName = f.filename;
      let lastDotIndex = baseName.lastIndexOf('.') ;
      if(lastDotIndex != -1) baseName = baseName.substring(0,lastDotIndex);
      this.PROMPT({ title: 'Rename File', message: 'New file name:', placeholder: baseName })
      .then(newName => {
        if(newName == "" || newName == baseName) return;
        let ext = this.fileExtension(f.filename);
        if(ext.length) newName = `${newName}.${ext}`;
        let data = { filename: newName };
        this.API({ method: 'PATCH', endpoint: `filebox/files/${f.xid}`, data })
        .finally(this.refresh);
      });
    },
    deleteFile(file){
      this.CONFIRM({ title: "Delete File", message: `Delete the file "${file.filename}"?`})
      .then(()=>{
        this.API({ method: 'DELETE', endpoint: `filebox/files/${file.xid}` })
        .then(this.refresh);
      });
    },
    attachFile(item){
      this.jobPicker = { ...this.jobPicker, item, job: null };
    },
    attachFileSubmit(){
      this.jobPicker.loading = true;
      let data = { job: this.jobPicker.job.xid };
      return this.API({ method: 'POST', endpoint: `filebox/files/${this.jobPicker.item.file.xid}/attach`, data })
      .then(() => { this.jobPicker.item = null; this.refresh(); })
      .finally(() => { this.jobPicker.loading = false; });
    },
    attachFileSubmitAndGo(){
      this.attachFileSubmit()
      .then(() => {
        this.NAVIGATE(`/dash/jobs/all/${this.jobPicker.job.xid}`);
      })
    },
    showInstruction(){
      this.ALERT({
        title: "File Inbox",
        message: [
          "You may receive files by sending or forwarding emails to the following address:",
          "",
          "files@my.mountglass.com.au",
          "",
          "Any attachments to the email will be saved with the subject line as the file description."
        ].join("\n")
      });
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>
