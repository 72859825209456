<template>
  <div>
    <span v-if='false'><b>Search</b> {{searchText}}, <b>Current</b> {{optionSelected}}, <b>Results</b> {{options}}</span>
    <v-autocomplete
      @update:search-input='search' no-filter
      :items='options' item-text='number' item-value='xid' return-object
      :loading='loading'
      :value='optionSelected'
      @change='change' clearable
      outlined color='blue'
      placeholder='Job Number...'
      :no-data-text='noDataText'
      hide-details
      prepend-icon='mdi-briefcase' @click:prepend='clickIcon'
    >
      <template #item="{item}">
        <div class=''>
          <div class='d-flex align-center'>
            <span class='font-weight-medium primary--text'>{{item.number}}</span>
            <span class='text-caption ml-2 font-italic'>{{item.description}}<br /></span>
          </div>
          <div class='text-caption mb-1' v-if='item.customer'>{{item.customer.name}}<br /></div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'JobSelector',
  components: {},
  props: {
    value: { type: Object, required: false, default: null },
  },
  data(){
    return {
      optionSelected: null,
      options: [],
      searchText: null,
      loading: false,
    }
  },
  watch: {
    value(newValue){
      this.optionSelected = newValue;
      this.options = newValue !== null ? [newValue] : [];
    }
  },
  computed: {
    noDataText(){
      if(this.loading) return "Searching...";
      return "No search results";
    }
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["ALERT", "NAVIGATE"]),

    search(search){
      if(!search) return;
      if(search === this.optionSelected?.number) return;
      this.searchText = search;
      this.loading = true;
      this.API({method: 'POST', endpoint: `search/jobs`, data: { search }, autosave: 250 })
      .then(results => {
        this.options = [
          ...(this.optionSelected ? [this.optionSelected] : []),
          ...results.map(r => r.data)
        ];
      })
      .finally(()=>{ this.loading = false; })
    },

    change(newSelection){
      this.optionSelected = newSelection;
      this.$emit('change', newSelection);
    },

    clickIcon(){
      if(!this.optionSelected)
        this.ALERT({ title: 'Job', message: 'No job selected.' });
      else
        this.NAVIGATE(`/dash/jobs/all/${this.optionSelected.xid}`);
    }

  },
  mounted(){
    this.options = this.value !== null ? [this.value] : [];
    this.optionSelected = this.value;
  }
}
</script>
