export default {
  _default: { icon: 'mdi-file-outline', colour: 'secondary', action: 'download' },

  pdf: { icon: 'mdi-file', colour: 'red darken-1', action: 'new_tab' },

  txt: { icon: 'mdi-file-document', colour: 'blue darken-3', action: 'new_tab' },
  rtf: { icon: 'mdi-file-document', colour: 'blue darken-3', action: 'download' },
  doc: { icon: 'mdi-file-word', colour: 'blue darken-3', action: 'download' },
  docx: { icon: 'mdi-file-word', colour: 'blue darken-3', action: 'download' },

  csv: { icon: 'mdi-file-table', colour: 'green darken-3', action: 'download' },
  xls: { icon: 'mdi-file-excel', colour: 'green darken-3', action: 'download' },
  xlsx: { icon: 'mdi-file-excel', colour: 'green darken-3', action: 'download' },

  zip: { icon: 'mdi-folder-zip', colour: 'yellow darken-3', action: 'download' },
  rar: { icon: 'mdi-folder-zip', colour: 'yellow darken-3', action: 'download' },

  jpg: { icon: 'mdi-file-image', colour: 'orange darken-3', action: 'image_viewer' },
  jpeg: { icon: 'mdi-file-image', colour: 'orange darken-3', action: 'image_viewer' },
  bmp: { icon: 'mdi-file-image', colour: 'orange darken-3', action: 'image_viewer' },
  png: { icon: 'mdi-file-image', colour: 'orange darken-3', action: 'image_viewer' },
  gif: { icon: 'mdi-file-image', colour: 'orange darken-3', action: 'image_viewer' },

}
