var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [false ? _c('span', [_c('b', [_vm._v("Search")]), _vm._v(" " + _vm._s(_vm.searchText) + ", "), _c('b', [_vm._v("Current")]), _vm._v(" " + _vm._s(_vm.optionSelected) + ", "), _c('b', [_vm._v("Results")]), _vm._v(" " + _vm._s(_vm.options))]) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "no-filter": "",
      "items": _vm.options,
      "item-text": "number",
      "item-value": "xid",
      "return-object": "",
      "loading": _vm.loading,
      "value": _vm.optionSelected,
      "clearable": "",
      "outlined": "",
      "color": "blue",
      "placeholder": "Job Number...",
      "no-data-text": _vm.noDataText,
      "hide-details": "",
      "prepend-icon": "mdi-briefcase"
    },
    on: {
      "update:search-input": _vm.search,
      "change": _vm.change,
      "click:prepend": _vm.clickIcon
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {}, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "font-weight-medium primary--text"
        }, [_vm._v(_vm._s(item.number))]), _c('span', {
          staticClass: "text-caption ml-2 font-italic"
        }, [_vm._v(_vm._s(item.description)), _c('br')])]), item.customer ? _c('div', {
          staticClass: "text-caption mb-1"
        }, [_vm._v(_vm._s(item.customer.name)), _c('br')]) : _vm._e()])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }