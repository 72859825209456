var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-8"
  }, [_c('v-card', {
    staticClass: "rounded-lg",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_c('span', [_vm._v("Files Received")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.showInstruction
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "dataTable",
    attrs: {
      "dense": "",
      "items-per-page": -1,
      "hide-default-footer": "",
      "items": _vm.files,
      "item-key": "xid",
      "headers": _vm.headers,
      "no-data-text": "No Files"
    },
    scopedSlots: _vm._u([{
      key: "item.file",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center clickFile",
          on: {
            "click": function click($event) {
              return _vm.clickFile(item.file);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": _vm.ext(item.file).colour,
            "size": "28"
          }
        }, [_vm._v(_vm._s(_vm.ext(item.file).icon))]), _c('div', [_vm._v(_vm._s(item.file.filename))])], 1)];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('span', _vm._g(_vm._b({
                staticClass: "font-italic text-caption"
              }, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.moment(item.created_at).from()))])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.moment(item.created_at).format('MMM D, Y')))])])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.renameFile(item.file);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-rename-box")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.attachFile(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-briefcase-plus")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.downloadFile(item.file);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-download")])], 1), _c('v-btn', {
          staticClass: "red--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteFile(item.file);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.jobPicker.item !== null,
      "persistent": "",
      "max-width": "500"
    }
  }, [_vm.jobPicker.item !== null ? _c('v-card', {
    attrs: {
      "loading": _vm.jobPicker.loading ? "white" : false,
      "disabled": _vm.jobPicker.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Attach to Job ")]), _c('v-card-text', {
    staticClass: "py-4 mb-0 secondary--text"
  }, [_c('div', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": _vm.ext(_vm.jobPicker.item.file).colour,
      "size": "28"
    }
  }, [_vm._v(_vm._s(_vm.ext(_vm.jobPicker.item.file).icon))]), _c('span', {
    staticClass: "black--text"
  }, [_vm._v(_vm._s(_vm.jobPicker.item.file.filename))])], 1), _c('div', {
    staticClass: "font-italic text-caption my-1 ml-9"
  }, [_vm._v(_vm._s(_vm.jobPicker.item.subject))]), _c('JobSelector', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.jobPicker.job
    },
    on: {
      "change": function change(j) {
        _vm.jobPicker.job = j;
      }
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        _vm.jobPicker.item = null;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1",
      "disabled": _vm.jobPicker.job === null
    },
    on: {
      "click": _vm.attachFileSubmit
    }
  }, [_vm._v("Attach")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1",
      "disabled": _vm.jobPicker.job === null
    },
    on: {
      "click": _vm.attachFileSubmitAndGo
    }
  }, [_vm._v("Attach & Go")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }